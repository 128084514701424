const packageVersion = require("./package.json").version;
const Modal = require("react-modal");
const { setLocale } = require("yup");

setLocale({
  mixed: {
    required: "Required",
  },
  string: {
    email: "Invalid email address",
  },
});

function onClientEntry() {
  import("./src/styles/index.scss");
  // Is there a contentful hash for the used contentful state and/or date used
  console.log("Site Version", packageVersion);

  Modal.setAppElement("#___gatsby");

  if (process.env.GATSBY_USE_MSW_MOCKS) {
    require("./service-mocks");
  }
}

export { onClientEntry };
