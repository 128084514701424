exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-dmi-ecosystem-js": () => import("./../../../src/pages/dmi-ecosystem.js" /* webpackChunkName: "component---src-pages-dmi-ecosystem-js" */),
  "component---src-pages-dmi-platform-js": () => import("./../../../src/pages/dmi-platform.js" /* webpackChunkName: "component---src-pages-dmi-platform-js" */),
  "component---src-pages-dmi-process-js": () => import("./../../../src/pages/dmi-process.js" /* webpackChunkName: "component---src-pages-dmi-process-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-press-room-js": () => import("./../../../src/pages/press-room.js" /* webpackChunkName: "component---src-pages-press-room-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-press-release-js": () => import("./../../../src/templates/PressRelease.js" /* webpackChunkName: "component---src-templates-press-release-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

