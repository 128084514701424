import { rest, setupWorker } from "msw";

async function simulateDelay() {
  return new Promise((resolve) => setTimeout(resolve, 1500));
}

const paths = ["contacts", "consultations", "document-views", "registrations"];

const worker = setupWorker(
  ...paths.map((p) =>
    rest.post(`/api/${p}`, async (req, res, ctx) => {
      console.log("API call received at", p);
      await simulateDelay();
      return res(ctx.status(201), ctx.json(true));
    })
  )
);

worker.start({ onUnhandledRequest: "bypass" });
console.info("🔶 Mock services started");
